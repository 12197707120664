import * as React from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import { CircularProgress, Stack } from "@mui/material"

const steps = ["Select Options", "Condition Repport", "Offer"]

export default function StepperWrapper({
  children,
  activeStep,
  setActiveStep,
  handleNext,
  setOpen,
  disabled,
  loading,
}) {
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        // position: "relative",
      }}
    >
      {loading && (
        <Stack
          position={"absolute"}
          sx={{
            inset: 0,
            alignItems: "center",
            bgcolor: "#ffffffab",
            py: 10,
            zIndex: 9999,
          }}
          spacing={2}
        >
          <CircularProgress />
          <Typography variant="subtitle1">
            Your request is in progress. Please wait...
          </Typography>
        </Stack>
      )}
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {}

          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>

      <Stack height={"100%"} justifyContent={"space-between"} flex={1}>
        <Stack height={"100%"} flex={1} py={5}>
          {children}
        </Stack>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            color="inherit"
            disabled={activeStep === 0 || disabled || loading}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            disabled={ loading}
            color="error"
            onClick={() => setOpen(false)}
            sx={{ mr: 1 }}
          >
            Close
          </Button>
          {
            //   activeStep !== steps.length - 1 &&
            <Button disabled={disabled || loading} onClick={handleNext}>
              {activeStep === 1
                ? "Get Offer"
                : activeStep === steps.length - 1
                ? "Make Offer"
                : "Next"}
            </Button>
          }
        </Box>
      </Stack>
    </Box>
  )
}
